"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useDebounce = (callback, delay) => {
    const timer = (0, react_1.useRef)(null);
    return (...args) => {
        if (timer.current)
            clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            callback(...args);
            timer.current = null;
        }, delay);
    };
};
exports.default = useDebounce;
