"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useErrorImageReplace = (imageUrl, replaceImageUrl) => {
    const imageRef = (0, react_1.useRef)(undefined);
    (0, react_1.useEffect)(() => {
        if (!imageUrl) {
            return;
        }
        const image = new Image();
        const handleError = () => {
            imageRef.current.src = replaceImageUrl;
        };
        image.addEventListener("error", handleError);
        image.src = imageUrl;
        return () => {
            image.removeEventListener("error", handleError);
        };
    }, [imageUrl, replaceImageUrl]);
    return {
        imageRef,
    };
};
exports.default = useErrorImageReplace;
