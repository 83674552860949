"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePullUpToCallback = exports.usePullDownToCallback = exports.sleep = exports.getScrollParent = void 0;
const react_1 = require("react");
const react_2 = require("@use-gesture/react");
const defaultRoot = window;
const overflowStylePatterns = ["scroll", "auto", "overlay"];
const isElement = (node) => {
    const ELEMENT_NODE_TYPE = 1;
    return node.nodeType === ELEMENT_NODE_TYPE;
};
const getScrollParent = (el, root = defaultRoot) => {
    let node = el;
    while (node && node !== root && isElement(node)) {
        if (node === document.body) {
            return root;
        }
        const { overflowY } = window.getComputedStyle(node);
        if (overflowStylePatterns.includes(overflowY) && node.scrollHeight > node.clientHeight) {
            return node;
        }
        node = node.parentNode;
    }
    return root;
};
exports.getScrollParent = getScrollParent;
const sleep = (time) => new Promise((resolve) => {
    setTimeout(resolve, time);
});
exports.sleep = sleep;
const usePullDownToCallback = ({ headHeight = 40, threshold = 60, onCallback, disabled = false, completeDelay = 500, chatAreaRef, }) => {
    const [status, setStatus] = (0, react_1.useState)("pulling");
    const requestRef = (0, react_1.useRef)(0);
    const headRef = (0, react_1.useRef)(null);
    const pullingRef = (0, react_1.useRef)(false);
    (0, react_1.useEffect)(() => {
        return () => {
            cancelAnimationFrame(requestRef.current);
        };
    }, []);
    const slideDown = (height, cb) => {
        requestAnimationFrame(function animate() {
            if (headRef.current) {
                const currentHeight = headRef.current.clientHeight;
                if (currentHeight > height) {
                    const decreasing = currentHeight - height > 20 ? 5 : 1;
                    headRef.current.style.height = `${currentHeight - decreasing}px`;
                    requestRef.current = requestAnimationFrame(animate);
                }
                if (currentHeight === height) {
                    if (cb) {
                        cb();
                    }
                }
            }
        });
    };
    const slideUp = (height, cb) => {
        requestAnimationFrame(function animate() {
            if (headRef.current) {
                const currentHeight = headRef.current.clientHeight;
                if (currentHeight > height) {
                    headRef.current.style.height = `${currentHeight - 1}px`;
                    requestRef.current = requestAnimationFrame(animate);
                }
                if (currentHeight === height) {
                    if (cb) {
                        cb();
                    }
                }
            }
        });
    };
    const doRefresh = () => __awaiter(void 0, void 0, void 0, function* () {
        slideDown(headHeight);
        setStatus("refreshing");
        try {
            yield (onCallback === null || onCallback === void 0 ? void 0 : onCallback());
            setStatus("complete");
        }
        catch (e) {
            slideDown(0, () => {
                setStatus("pulling");
            });
            setStatus("pulling");
            throw e;
        }
        if (completeDelay > 0) {
            yield (0, exports.sleep)(completeDelay);
        }
        slideDown(0, () => {
            setStatus("pulling");
        });
        setStatus("pulling");
    });
    (0, react_2.useDrag)((state) => {
        if (status === "refreshing" || status === "complete")
            return;
        const { event } = state;
        if (state.last) {
            pullingRef.current = false;
            if (status === "canRelease") {
                doRefresh();
            }
            else {
                slideUp(0);
            }
            return;
        }
        const [, y] = state.movement;
        const getScrollTop = (element) => {
            return "scrollTop" in element ? element.scrollTop : element.scrollY;
        };
        if (state.first && y > 0) {
            const { target } = state.event;
            if (!target || !(target instanceof Element))
                return;
            let scrollParent = (0, exports.getScrollParent)(target);
            while (true) {
                if (!scrollParent)
                    return;
                const scrollTop = getScrollTop(scrollParent);
                if (scrollTop > 0) {
                    return;
                }
                if (scrollParent instanceof Window) {
                    break;
                }
                scrollParent = (0, exports.getScrollParent)(scrollParent.parentNode);
            }
            pullingRef.current = true;
        }
        if (!pullingRef.current)
            return;
        if (event.cancelable) {
            event.preventDefault();
        }
        event.stopPropagation();
        if (headRef.current) {
            if (y < headHeight) {
                headRef.current.style.height = `${y}px`;
            }
            else {
                headRef.current.style.height = `${headHeight + (y - headHeight) * 0.25}px`;
            }
        }
        setStatus(y > threshold ? "canRelease" : "pulling");
    }, {
        pointer: { touch: true },
        axis: "y",
        target: chatAreaRef,
        enabled: !disabled,
        eventOptions: { passive: false },
    });
    return { headRef, headHeight };
};
exports.usePullDownToCallback = usePullDownToCallback;
const usePullUpToCallback = ({ tailHeight = 40, threshold = 60, onCallback, disabled = false, completeDelay = 500, chatAreaRef, }) => {
    const [status, setStatus] = (0, react_1.useState)("pulling");
    const requestRef = (0, react_1.useRef)(0);
    const tailRef = (0, react_1.useRef)(null);
    const pullingRef = (0, react_1.useRef)(false);
    (0, react_1.useEffect)(() => {
        return () => {
            cancelAnimationFrame(requestRef.current);
        };
    }, []);
    const slideDown = (height, cb) => {
        requestAnimationFrame(function animate() {
            if (tailRef.current) {
                const currentHeight = tailRef.current.clientHeight;
                if (currentHeight > height) {
                    const decreasing = currentHeight - height > 20 ? 5 : 1;
                    tailRef.current.style.height = `${currentHeight - decreasing}px`;
                    requestRef.current = requestAnimationFrame(animate);
                }
                if (currentHeight === height) {
                    if (cb) {
                        cb();
                    }
                }
            }
        });
    };
    const slideUp = (height, cb) => {
        requestAnimationFrame(function animate() {
            if (tailRef.current) {
                const currentHeight = tailRef.current.clientHeight;
                if (currentHeight > height) {
                    tailRef.current.style.height = `${currentHeight - 1}px`;
                    requestRef.current = requestAnimationFrame(animate);
                }
                if (currentHeight === height) {
                    if (cb) {
                        cb();
                    }
                }
            }
        });
    };
    const doRefresh = () => __awaiter(void 0, void 0, void 0, function* () {
        slideUp(tailHeight);
        setStatus("refreshing");
        try {
            yield (onCallback === null || onCallback === void 0 ? void 0 : onCallback());
            setStatus("complete");
        }
        catch (e) {
            slideUp(0, () => {
                setStatus("pulling");
            });
            setStatus("pulling");
            throw e;
        }
        if (completeDelay > 0) {
            yield (0, exports.sleep)(completeDelay);
        }
        slideUp(0, () => {
            setStatus("pulling");
        });
        setStatus("pulling");
    });
    (0, react_2.useDrag)((state) => {
        if (status === "refreshing" || status === "complete")
            return;
        const { event } = state;
        if (state.last) {
            pullingRef.current = false;
            if (status === "canRelease") {
                doRefresh();
            }
            else {
                slideDown(0);
            }
            return;
        }
        const [, y] = state.movement;
        const getScrollTop = (element) => {
            return "scrollTop" in element ? element.scrollTop : element.scrollY;
        };
        if (state.first && y <= 0) {
            const { target } = state.event;
            if (!target || !(target instanceof Element))
                return;
            let scrollParent = (0, exports.getScrollParent)(target);
            while (true) {
                if (!scrollParent)
                    return;
                const scrollTop = getScrollTop(scrollParent);
                if (scrollTop < 0) {
                    return;
                }
                if (scrollParent instanceof Window) {
                    break;
                }
                scrollParent = (0, exports.getScrollParent)(scrollParent.parentNode);
            }
            pullingRef.current = true;
        }
        if (!pullingRef.current)
            return;
        if (event.cancelable) {
            event.preventDefault();
        }
        event.stopPropagation();
        if (tailRef.current) {
            if (-y < tailHeight) {
                tailRef.current.style.height = `${-y}px`;
                tailRef.current.scrollIntoView();
            }
            else {
                tailRef.current.style.height = `${tailHeight + (-y - tailHeight) * 0.25}px`;
            }
        }
        setStatus(-y > threshold ? "canRelease" : "pulling");
    }, {
        pointer: { touch: true },
        axis: "y",
        target: chatAreaRef,
        enabled: !disabled,
        eventOptions: { passive: false },
    });
    return { tailRef, tailHeight };
};
exports.usePullUpToCallback = usePullUpToCallback;
