"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("@/constants");
const diner_1 = require("@/domains/diner");
const react_1 = require("react");
const stack_1 = require("@/stack");
const useDinerMap = ({ userCoordinates, isReloaded, isSelected }) => {
    const { push } = (0, stack_1.useFlow)();
    const { data: dinerPins, isLoading: isDinerPinsLoading } = (0, diner_1.useDinerPinsQuery)({
        lat: userCoordinates ? userCoordinates.lat : 0,
        lng: userCoordinates ? userCoordinates.lng : 0,
    }, {
        enabled: isSelected && isReloaded,
        keepPreviousData: true,
        suspense: false,
    });
    const { data: diners, isLoading: isDinersLoading } = (0, diner_1.useDinersQuery)({
        lat: userCoordinates ? userCoordinates.lat : 0,
        lng: userCoordinates ? userCoordinates.lng : 0,
    }, {
        enabled: isSelected && isReloaded,
        keepPreviousData: true,
        suspense: false,
    });
    const navigateToDinerDetail = (0, react_1.useCallback)((id, userAddress) => {
        push(constants_1.PATH.DINER.stack, { dinerId: id, userAddress });
    }, [push]);
    return {
        dinerPins,
        isDinerPinsLoading,
        diners,
        isDinersLoading,
        navigateToDinerDetail,
    };
};
exports.default = useDinerMap;
