"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_IMAGE = exports.REPORTS = exports.ACCOMMODATION_REVIEW_TAGS = exports.ACCOMMODATION_INFO_TAGS = exports.DINER_REVIEWS = exports.CAFE_REVIEWS = exports.DESIRED_ACTIVITIES = exports.YEAR_OF_SERVICES = exports.JOBS = void 0;
exports.JOBS = [
    {
        name: "ENGINEER",
        content: "개발",
    },
    {
        name: "DESIGNER",
        content: "디자이너",
    },
    {
        name: "PRODUCT",
        content: "기획 / PM",
    },
    {
        name: "MARKETING",
        content: "마케팅",
    },
    {
        name: "OFFICE",
        content: "사무직",
    },
    {
        name: "EXPERT",
        content: "전문직",
    },
    {
        name: "FREE",
        content: "프리랜서",
    },
];
exports.YEAR_OF_SERVICES = [
    {
        name: "NEW_STAFF",
        content: "1-2년차",
    },
    {
        name: "JUNIOR",
        content: "3-4년차",
    },
    {
        name: "MID_LEVEL",
        content: "5-6년차",
    },
    {
        name: "SENIOR",
        content: "7-8년차",
    },
    {
        name: "EXPERT",
        content: "9-10년차",
    },
    {
        name: "OVER",
        content: "11년차~",
    },
];
exports.DESIRED_ACTIVITIES = [
    {
        name: "JUNIOR",
        content: "주니어모여라",
    },
    {
        name: "SENIOR",
        content: "시니어모여라",
    },
    {
        name: "DESIGN",
        content: "디자인같이해요",
    },
    {
        name: "DEVELOP",
        content: "개발같이해요",
    },
    {
        name: "PLAN",
        content: "기획같이해요",
    },
    {
        name: "IT",
        content: "IT함께해요",
    },
    {
        name: "FINANCIAL",
        content: "금융함께해요",
    },
    {
        name: "COMMERCE",
        content: "이커머스함께해요",
    },
    {
        name: "BUSINESS",
        content: "제조업함께해요",
    },
    {
        name: "STARTUP",
        content: "스타트업함께해요",
    },
    {
        name: "PASSION",
        content: "열정맨",
    },
    {
        name: "TALK",
        content: "직무토크하실분",
    },
    {
        name: "CAREER",
        content: "진로고민",
    },
    {
        name: "DINNER",
        content: "저녁메이트구해요",
    },
    {
        name: "LUNCH",
        content: "점심메이트구해요",
    },
    {
        name: "AFTER_WORK",
        content: "퇴근후함께놀아요",
    },
];
exports.CAFE_REVIEWS = [
    {
        name: "WIFI",
        content: "와이파이가\n빵빵해요",
    },
    {
        name: "MEAL",
        content: "식사 메뉴가\n있어요",
    },
    {
        name: "QUIET",
        content: "조용한 공간이\n있어요",
    },
    {
        name: "POWER",
        content: "콘센트 자리\n많아요",
    },
    {
        name: "SEAT",
        content: "좌석이 업무하기\n좋아요",
    },
    {
        name: "VIEW",
        content: "뷰가\n좋아요",
    },
    {
        name: "COST",
        content: "가성비가\n좋아요",
    },
    {
        name: "PARKING",
        content: "주차하기\n편해요",
    },
    {
        name: "SPACE",
        content: "넓고\n깨끗해요",
    },
    {
        name: "NOT_CROWDED",
        content: "사람이 많이\n없어요",
    },
];
exports.DINER_REVIEWS = [
    {
        name: "QUICK_FOOD",
        content: "음식이 빨리\n나와요",
    },
    {
        name: "EAT_ALONE",
        content: "혼밥\n가능해요",
    },
    {
        name: "MUST_GO",
        content: "맛집이에요",
    },
    {
        name: "QUIET",
        content: "조용해요",
    },
    {
        name: "SNACK",
        content: "간단히 먹기\n좋아요",
    },
    {
        name: "VIEW",
        content: "뷰가\n좋아요",
    },
    {
        name: "COST",
        content: "가성비가\n좋아요",
    },
    {
        name: "PARKING",
        content: "주차하기\n편해요",
    },
    {
        name: "SPACE",
        content: "넓고\n깨끗해요",
    },
    {
        name: "NOT_CROWDED",
        content: "사람이 많이\n없어요",
    },
];
exports.ACCOMMODATION_INFO_TAGS = [
    {
        name: "WORKSPACE",
        content: "숙소 내\n업무 공간",
        icon: "WORKSPACE",
    },
    {
        name: "NEAR_FOREST",
        content: "숲 인근",
        icon: "NEAR_FOREST",
    },
    {
        name: "NEAR_SEA",
        content: "바다 인근",
        icon: "NEAR_SEA",
    },
    {
        name: "NEAR_ATTRACTION",
        content: "관광지\n인근",
        icon: "NEAR_ATTRACTION",
    },
    {
        name: "NEAR_CITY",
        content: "도시 인근",
        icon: "NEAR_CITY",
    },
    {
        name: "SHARED_WORKSPACE",
        content: "공용업무\n공간",
        icon: "SHARED_WORKSPACE",
    },
];
exports.ACCOMMODATION_REVIEW_TAGS = [
    {
        name: "WIFI",
        content: "와이파이빵빵해요",
        preContent: "와이파이가\n빵빵해요",
    },
    {
        name: "POWER",
        content: "콘센트많아요",
        preContent: "콘센트자리\n많아요",
    },
    {
        name: "SEAT",
        content: "좌석이편해요",
        preContent: "좌석이업무하기\n좋아요",
    },
    {
        name: "QUIET",
        content: "조용한공간있어요",
        preContent: "조용한 공간이\n있어요",
    },
    {
        name: "FOCUS",
        content: "집중잘돼요",
        preContent: "집중이\n잘돼요",
    },
    {
        name: "SERVE_MEAL",
        content: "식사가능해요",
        preContent: "식사메뉴가\n있어요",
    },
];
exports.REPORTS = ["INQUERY", "SERVICE_OFFER", "ERROR_REPORT", "OTHERS"];
exports.DEFAULT_IMAGE = "https://images.unsplash.com/photo-1483648969698-5e7dcaa3444f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8Y2FmZSUyMGRpbmVyfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60";
