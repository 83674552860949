"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const browser_1 = require("@/utils/browser");
const react_1 = require("react");
const useCoordinates = () => {
    const [userCoordinates, setUserCoordinates] = (0, react_1.useState)();
    (0, react_1.useEffect)(() => {
        const watchId = navigator.geolocation.watchPosition(({ coords }) => {
            setUserCoordinates({
                lat: coords.latitude,
                lng: coords.longitude,
            });
        }, (error) => {
            (0, browser_1.alertUserPositionLoadError)(error);
        }, {
            maximumAge: 1000 * 10,
        });
        return () => navigator.geolocation.clearWatch(watchId);
    }, []);
    return { userCoordinates };
};
exports.default = useCoordinates;
