"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMultiselect = void 0;
const react_1 = require("react");
const useMultiselect = (initialValue) => {
    const [selected, setSelected] = (0, react_1.useState)(initialValue);
    const onChange = (event) => {
        const value = event.target.value;
        const index = selected.indexOf(value);
        if (index > -1) {
            setSelected([...selected.slice(0, index), ...selected.slice(index + 1)]);
        }
        else {
            setSelected([...selected, ...[value]]);
        }
    };
    const onChangeOnly = (event) => {
        const value = event.target.value;
        const index = selected.indexOf(value);
        setSelected([value]);
        if (index > -1) {
            setSelected([]);
        }
        else {
            setSelected([value]);
        }
    };
    const isSelected = (value) => {
        return selected.includes(value);
    };
    return { selected, isSelected, onChange, onChangeOnly };
};
exports.useMultiselect = useMultiselect;
