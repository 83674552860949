"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const cafe_1 = require("@/domains/cafe");
const react_1 = require("react");
const stack_1 = require("@/stack");
const constants_1 = require("@/constants");
const useCafeMap = ({ userCoordinates, isReloaded, isSelected }) => {
    const { push } = (0, stack_1.useFlow)();
    const { data: cafePins, isLoading: isCafePinsLoading } = (0, cafe_1.useCafePinsQuery)({
        lat: userCoordinates ? userCoordinates.lat : 0,
        lng: userCoordinates ? userCoordinates.lng : 0,
    }, {
        enabled: isSelected && isReloaded,
        keepPreviousData: true,
        suspense: false,
    });
    const { data: cafes, isLoading: isCafesLoading } = (0, cafe_1.useCafesQuery)({
        lat: userCoordinates ? userCoordinates.lat : 0,
        lng: userCoordinates ? userCoordinates.lng : 0,
    }, {
        enabled: isSelected && isReloaded,
        keepPreviousData: true,
        suspense: false,
    });
    const navigateToCafeDetail = (0, react_1.useCallback)((id, userAddress) => {
        push(constants_1.PATH.CAFE.stack, { cafeId: id, userAddress });
    }, [push]);
    return {
        cafePins,
        isCafePinsLoading,
        cafes,
        isCafesLoading,
        navigateToCafeDetail,
    };
};
exports.default = useCafeMap;
