"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModalContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_dom_1 = require("react-dom");
const S = __importStar(require("./ModalContainer.styled"));
const $ModalContainer = document.getElementById("modal");
const ModalContainer = ({ isOpen, children, onClose, onConfirm, confirmText, closeOnClickOverlay = true, }) => {
    const modalRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        $ModalContainer === null || $ModalContainer === void 0 ? void 0 : $ModalContainer.setAttribute("aria-hidden", "true");
        document.body.style.overflow = "hidden";
        const handleModalCloseWithEscHandler = ({ key }) => {
            let scheduledAnimationFrame = false;
            if (scheduledAnimationFrame) {
                return;
            }
            scheduledAnimationFrame = true;
            if (key === "Escape" && onClose) {
                onClose();
                scheduledAnimationFrame = false;
            }
        };
        document.addEventListener("keyup", handleModalCloseWithEscHandler);
        const handleFocusTrap = (e) => {
            var _a;
            const focusableNodeList = (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.querySelectorAll("input, button, textarea, select, [href] [tabindex]");
            if (focusableNodeList) {
                const firstFocusableNode = focusableNodeList[0];
                const lastFocusableNode = focusableNodeList[focusableNodeList.length - 1];
                if (e.target === firstFocusableNode && e.shiftKey && e.key === "Tab") {
                    e.preventDefault();
                    lastFocusableNode.focus();
                }
                if (e.target === lastFocusableNode && !e.shiftKey && e.key === "Tab") {
                    e.preventDefault();
                    firstFocusableNode.focus();
                }
            }
        };
        document.addEventListener("keydown", handleFocusTrap);
        return () => {
            $ModalContainer === null || $ModalContainer === void 0 ? void 0 : $ModalContainer.removeAttribute("aria-hidden");
            document.body.style.overflow = "unset";
            document.removeEventListener("keyup", handleModalCloseWithEscHandler);
            document.removeEventListener("keydown", handleFocusTrap);
        };
    }, [closeOnClickOverlay, onClose]);
    const handleClickDimmed = (0, react_1.useCallback)((e) => {
        if (e.target === e.currentTarget && closeOnClickOverlay && onClose) {
            onClose(e);
        }
    }, [closeOnClickOverlay, onClose]);
    return ((0, jsx_runtime_1.jsx)(S.Container, Object.assign({ ref: modalRef, isOpen: isOpen, onClick: handleClickDimmed, tabIndex: -1 }, { children: (0, jsx_runtime_1.jsxs)(S.Dialog, Object.assign({ isOpen: isOpen }, { children: [(0, jsx_runtime_1.jsx)(S.Body, { children: children }), (0, jsx_runtime_1.jsx)(S.Foot, { children: onConfirm && onClose ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(S.CancelButton, Object.assign({ onClick: onClose }, { children: "\uCDE8\uC18C" })), (0, jsx_runtime_1.jsx)(S.ConfirmButton, Object.assign({ onClick: onConfirm }, { children: confirmText }))] })) : ((0, jsx_runtime_1.jsx)(S.ConfirmButton, Object.assign({ onClick: onClose }, { children: "\uD655\uC778" }))) })] })) })));
};
exports.ModalContainer = ModalContainer;
const ModalContainerPortal = (props) => {
    if (!$ModalContainer) {
        throw Error("modal 요소가 HTML 상에 존재하지 않습니다.");
    }
    return (0, react_dom_1.createPortal)((0, jsx_runtime_1.jsx)(exports.ModalContainer, Object.assign({}, props)), $ModalContainer);
};
exports.default = ModalContainerPortal;
