"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_kakao_maps_sdk_1 = require("react-kakao-maps-sdk");
const Pin = ({ lat, lng, image, size, onClick }) => {
    return ((0, jsx_runtime_1.jsx)(react_kakao_maps_sdk_1.MapMarker, { position: { lat, lng }, onClick: onClick, image: {
            size: size,
            src: image,
        } }));
};
exports.default = (0, react_1.memo)(Pin);
