"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDinersQuery = exports.requestGetDiners = exports.usePostDinerReview = exports.requestPostDinerReview = exports.useDinerReviewListQuery = exports.requestDinerReviewList = exports.useDinerDetailQuery = exports.requestGetDinerDetail = exports.useDinerPinsQuery = exports.requestGetDinerPins = void 0;
const react_query_1 = require("react-query");
const Mapper = __importStar(require("./diner.mapper"));
const constants_1 = require("@/constants");
const services_1 = require("@/services");
const requestGetDinerPins = ({ queryKey }) => __awaiter(void 0, void 0, void 0, function* () {
    const [, criteria] = queryKey;
    const data = yield (0, services_1.baseInstance)().get("map/restaurants/pin", {
        params: Mapper.a2dMapper_DinerPinsCriteria_GetDinerPinsRequest(criteria),
    });
    const dinerPins = Mapper.d2aMapper_GetDinerPinsResponse_DinerPinsInfo(data);
    return dinerPins;
});
exports.requestGetDinerPins = requestGetDinerPins;
const useDinerPinsQuery = (criteria, options) => {
    return (0, react_query_1.useQuery)([constants_1.QUERY_NAME.GET_DINER_PINS, criteria], exports.requestGetDinerPins, options);
};
exports.useDinerPinsQuery = useDinerPinsQuery;
const requestGetDinerDetail = ({ queryKey, }) => __awaiter(void 0, void 0, void 0, function* () {
    const [, criteria] = queryKey;
    const data = yield (0, services_1.baseInstance)().get(`map/restaurants/${criteria.id}`);
    const dinerDetail = Mapper.d2aMapper_GetDinerDetailResponse_DinerDetailInfo(data);
    return dinerDetail;
});
exports.requestGetDinerDetail = requestGetDinerDetail;
const useDinerDetailQuery = (criteria, options) => {
    return (0, react_query_1.useQuery)([constants_1.QUERY_NAME.GET_DINER_DETAIL, criteria], exports.requestGetDinerDetail, options);
};
exports.useDinerDetailQuery = useDinerDetailQuery;
const requestDinerReviewList = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, services_1.baseInstance)().get(constants_1.API_URL.GET_DINER_REVIEW_LIST);
});
exports.requestDinerReviewList = requestDinerReviewList;
const useDinerReviewListQuery = () => {
    return (0, react_query_1.useQuery)([constants_1.QUERY_NAME.GET_DINER_REVIEW_LIST], exports.requestDinerReviewList, { staleTime: Infinity, cacheTime: Infinity, keepPreviousData: true });
};
exports.useDinerReviewListQuery = useDinerReviewListQuery;
const requestPostDinerReview = ({ reviewTypeNames, locationId, }) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, services_1.baseInstance)().post(constants_1.API_URL.POST_DINER_REVIEW(locationId), { reviewTypeNames });
});
exports.requestPostDinerReview = requestPostDinerReview;
const usePostDinerReview = () => {
    const queryClient = (0, react_query_1.useQueryClient)();
    return (0, react_query_1.useMutation)({
        mutationFn: exports.requestPostDinerReview,
        onSuccess: (_, request) => {
            queryClient.invalidateQueries([constants_1.QUERY_NAME.GET_DINER_DETAIL, { id: request.locationId }]);
            queryClient.invalidateQueries([constants_1.QUERY_NAME.GET_DINERS]);
        },
    });
};
exports.usePostDinerReview = usePostDinerReview;
const requestGetDiners = ({ queryKey }) => __awaiter(void 0, void 0, void 0, function* () {
    const [, criteria] = queryKey;
    const data = yield (0, services_1.baseInstance)().get("/map/restaurants", {
        params: Mapper.a2dMapper_DinersCriteria_GetDinersRequest(criteria),
    });
    const diners = Mapper.d2aMapper_GetDinersResponse_DinersInfo(data);
    return diners;
});
exports.requestGetDiners = requestGetDiners;
const useDinersQuery = (criteria, options) => {
    return (0, react_query_1.useQuery)([constants_1.QUERY_NAME.GET_DINERS, criteria], exports.requestGetDiners, options);
};
exports.useDinersQuery = useDinersQuery;
