"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useDetectOutsideClick = (ref, eventHandler) => {
    (0, react_1.useEffect)(() => {
        const handleCorrespondRefAndTarget = (event) => {
            if ((ref === null || ref === void 0 ? void 0 : ref.current) && ref.current.contains(event.target)) {
                return;
            }
            eventHandler(event);
        };
        window.addEventListener("click", handleCorrespondRefAndTarget);
        return () => {
            window.removeEventListener("click", eventHandler);
        };
    }, [eventHandler, ref]);
};
exports.default = useDetectOutsideClick;
