"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const S = __importStar(require("./Tabs.styled"));
const Tabs = ({ selectedId, items, onSelect }) => {
    const selectedItemIndex = items.findIndex((item) => item.id === selectedId);
    const handleTabItemSelect = (0, react_1.useCallback)((id) => {
        const newSelectedItemIndex = items.findIndex((item) => item.id === id);
        if (newSelectedItemIndex === undefined) {
            throw Error("선택된 탭 아이템을 찾을 수 없습니다.");
        }
        onSelect(id);
    }, [items, onSelect]);
    return ((0, jsx_runtime_1.jsx)(S.TabWrap, { children: (0, jsx_runtime_1.jsxs)(S.TabList, Object.assign({ role: "tablist" }, { children: [items.map((item, index) => ((0, jsx_runtime_1.jsx)(S.TabItem, Object.assign({ itemCount: items.length }, { children: (0, jsx_runtime_1.jsx)(S.TabLink, Object.assign({ onClick: () => {
                            handleTabItemSelect(item.id);
                        } }, { children: (0, jsx_runtime_1.jsx)(S.TabText, Object.assign({ isSelected: index === selectedItemIndex }, { children: item.label })) })) }), index))), (0, jsx_runtime_1.jsx)(S.TabIndicatorWrapper, Object.assign({ itemCount: items.length, selectedItemIndex: selectedItemIndex }, { children: (0, jsx_runtime_1.jsx)(S.TabIndicator, {}) }))] })) }));
};
exports.default = Tabs;
