"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.d2aMapper_GetDinersResponse_DinersInfo = exports.a2dMapper_DinersCriteria_GetDinersRequest = exports.d2aMapper_GetDinerDetailResponse_DinerDetailInfo = exports.a2dMapper_DinerPinsCriteria_GetDinerPinsRequest = exports.d2aMapper_GetDinerPinsResponse_DinerPinsInfo = void 0;
const d2aMapper_GetDinerPinsResponse_DinerPinsInfo = (response) => {
    return response.data.locations.map((pin) => ({
        id: pin.id,
        coordinates: {
            lat: pin.latitude,
            lng: pin.longitude,
        },
    }));
};
exports.d2aMapper_GetDinerPinsResponse_DinerPinsInfo = d2aMapper_GetDinerPinsResponse_DinerPinsInfo;
const a2dMapper_DinerPinsCriteria_GetDinerPinsRequest = (criteria) => {
    return {
        latitude: criteria.lat,
        longitude: criteria.lng,
    };
};
exports.a2dMapper_DinerPinsCriteria_GetDinerPinsRequest = a2dMapper_DinerPinsCriteria_GetDinerPinsRequest;
const d2aMapper_GetDinerDetailResponse_DinerDetailInfo = (response) => {
    return {
        address: response.data.locationDetail.roadAddressName,
        imageUrl: response.data.locationDetail.fullImageUrl,
        kakaoLink: response.data.locationDetail.placeUrl,
        name: response.data.locationDetail.placeName,
        phoneNumber: response.data.locationDetail.phone,
        reviewPoints: response.data.locationReview.reviews.map((review) => ({
            icon: review.reviewType.name,
            reason: review.reviewType.content,
            reviewCount: review.count,
        })),
        isReviewed: response.data.locationReview.userReviewed,
        userCount: response.data.locationReview.userCount,
    };
};
exports.d2aMapper_GetDinerDetailResponse_DinerDetailInfo = d2aMapper_GetDinerDetailResponse_DinerDetailInfo;
const a2dMapper_DinersCriteria_GetDinersRequest = (criteria) => {
    return {
        latitude: criteria.lat,
        longitude: criteria.lng,
    };
};
exports.a2dMapper_DinersCriteria_GetDinersRequest = a2dMapper_DinersCriteria_GetDinersRequest;
const d2aMapper_GetDinersResponse_DinersInfo = (response) => {
    return response.data.locations.map((diner) => ({
        id: diner.id,
        imageUrl: diner.thumbnailImageUrl,
        name: diner.placeName,
        address: diner.roadAddressName,
        reviewCount: diner.reviewCount,
        tags: diner.topReviews.map((review) => review.content),
    }));
};
exports.d2aMapper_GetDinersResponse_DinersInfo = d2aMapper_GetDinersResponse_DinersInfo;
