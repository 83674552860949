"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.API_URL = void 0;
exports.API_URL = {
    POST_LOIGN: "/auth/token/kakao",
    POST_SIGNUP: "/user/signup",
    GET_VALIDATE_NICKNAME: (params) => `/user/validation?nickname=${params}`,
    GET_POSITION_LIST: "/user/job-department",
    GET_WORKING_YEAR_LIST: "/user/job-duration",
    GET_USER_LIST: "/user/self",
    GET_ACCOMMODATION_LIST: "/accommodations",
    GET_ACCOMMODATION_LIST_BY_NAME: "/accommodations/names",
    GET_ACCOMMODATION_DETAIL: (accommodationId) => `/accommodations/${accommodationId}`,
    GET_ACCOMMODATION_REVIEW: (accommodationId) => `/accommodations/${accommodationId}/reviews`,
    GET_TEST: (params) => `/${params}`,
    GET_INFINITE_TEST: (page, count) => `/something?page=${page}&count=${count}`,
    UPDATE_USER_PROFILE: "/user",
    UPDATE_USER_PROFILE_IMAGE: "/user/image",
    GET_CAFE_REVIEW_LIST: "/cafe/review-type",
    GET_DINER_REVIEW_LIST: "/food/review-type",
    GET_ACCOMMODATION_REVIEW_LIST: "/tags/accommodationReview",
    POST_CAFE_REVIEW: (locationId) => `/map/cafes/${locationId}/reviews`,
    POST_DINER_REVIEW: (locationId) => `/map/restaurants/${locationId}/reviews`,
    VERIFY_EMAIL: "/user/verify",
    EMAIL_VALIDATION_COUNT_REMAIL: "/user/verify/remaining-attempts",
    GET_CHAT_LIST: "/users/chattings",
    DELETE_CHAT: (roomId, lastMessageId) => `/chattings/${roomId}?lastMessageId=${lastMessageId}`,
    BLOCK_USER: (roomId) => `/chattings/${roomId}`,
    GET_CHAT_MESSAGES: (roomId, messageId, sortType) => `/chattings/${roomId}/messages${sortType ? `?sortType=${sortType}` : ""}${messageId ? `&messageId=${messageId}` : ""}`,
    POST_CHAT_SEND: (roomId, message) => `/chattings/${roomId}?message=${message}`,
    POST_LAST_MESSAGE: (roomId, lastMessageId) => `/users/chatting/${roomId}/lastMessage?lastMessageId=${lastMessageId}`,
    POST_CHAT_CONFIRM: (roomId) => `/chattings/${roomId}/confirm`,
    POST_CHAT_ROOM_CREATE: (ownerUserId) => `/chattings/?ownerUserId=${ownerUserId}`,
    POST_BLOCK_USER: (blockUserId) => `/user/blocking?blockUserId=${blockUserId}`,
    GET_REGION_TRAFFIC: (region) => `/region-traffic${region ? `?region=${region}` : ""}`,
    POST_USER_REPORT: "reports",
    PUT_USER_LOCATION_TRACKING: "/user/tracking",
    GET_ACCOMMODATION_CURATION: "/accommodations/curations",
};
