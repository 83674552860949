"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.d2aMapper_GetCafesResponse_CafesInfo = exports.a2dMapper_CafesCriteria_GetCafesRequest = exports.d2aMapper_GetCafeDetailResponse_CafeDetailInfo = exports.a2dMapper_CafePinsCriteria_GetCafePinsRequest = exports.d2aMapper_GetCafePinsResponse_CafePinsInfo = void 0;
const d2aMapper_GetCafePinsResponse_CafePinsInfo = (response) => {
    return response.data.locations.map((cafePin) => ({
        id: cafePin.id,
        coordinates: {
            lat: cafePin.latitude,
            lng: cafePin.longitude,
        },
    }));
};
exports.d2aMapper_GetCafePinsResponse_CafePinsInfo = d2aMapper_GetCafePinsResponse_CafePinsInfo;
const a2dMapper_CafePinsCriteria_GetCafePinsRequest = (criteria) => {
    return {
        latitude: criteria.lat,
        longitude: criteria.lng,
    };
};
exports.a2dMapper_CafePinsCriteria_GetCafePinsRequest = a2dMapper_CafePinsCriteria_GetCafePinsRequest;
const d2aMapper_GetCafeDetailResponse_CafeDetailInfo = (response) => {
    return {
        address: response.data.locationDetail.roadAddressName,
        imageUrl: response.data.locationDetail.fullImageUrl,
        kakaoLink: response.data.locationDetail.placeUrl,
        name: response.data.locationDetail.placeName,
        phoneNumber: response.data.locationDetail.phone,
        reviewPoints: response.data.locationReview.reviews.map((review) => ({
            icon: review.reviewType.name,
            reason: review.reviewType.content,
            reviewCount: review.count,
        })),
        isReviewed: response.data.locationReview.userReviewed,
        userCount: response.data.locationReview.userCount,
    };
};
exports.d2aMapper_GetCafeDetailResponse_CafeDetailInfo = d2aMapper_GetCafeDetailResponse_CafeDetailInfo;
const a2dMapper_CafesCriteria_GetCafesRequest = (criteria) => {
    return {
        latitude: criteria.lat,
        longitude: criteria.lng,
    };
};
exports.a2dMapper_CafesCriteria_GetCafesRequest = a2dMapper_CafesCriteria_GetCafesRequest;
const d2aMapper_GetCafesResponse_CafesInfo = (response) => {
    return response.data.locations.map((cafe) => ({
        id: cafe.id,
        imageUrl: cafe.thumbnailImageUrl,
        name: cafe.placeName,
        address: cafe.roadAddressName,
        reviewCount: cafe.reviewCount,
        tags: cafe.topReviews.map((review) => review.content),
    }));
};
exports.d2aMapper_GetCafesResponse_CafesInfo = d2aMapper_GetCafesResponse_CafesInfo;
