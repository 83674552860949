"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const usePageVisibility = ({ callback, delay = 0 }) => {
    const timeoutId = (0, react_1.useRef)(null);
    const browserCompatApi = () => {
        if ("mozHidden" in document) {
            return {
                hidden: "mozHidden",
                visibilityChange: "mozvisibilitychange",
            };
        }
        if ("webkitHidden" in document) {
            return {
                hidden: "webkitHidden",
                visibilityChange: "webkitvisibilitychange",
            };
        }
        return {
            hidden: "hidden",
            visibilityChange: "visibilitychange",
        };
    };
    const cleanupTimeout = () => timeoutId.current && clearTimeout(timeoutId.current);
    (0, react_1.useEffect)(() => {
        const { hidden, visibilityChange } = browserCompatApi();
        if (typeof callback !== "function") {
            throw new Error("callback must be a function");
        }
        const handleVisibilityChange = () => {
            if (delay) {
                if (typeof delay !== "number" || delay < 0) {
                    throw new Error("delay must be a positive integer");
                }
                if (timeoutId.current)
                    cleanupTimeout();
                timeoutId.current = setTimeout(() => callback(!document[hidden]), delay);
            }
            else {
                callback(!document[hidden]);
            }
        };
        document.addEventListener(visibilityChange, handleVisibilityChange);
        return () => {
            document.removeEventListener(visibilityChange, handleVisibilityChange);
        };
    }, [callback, delay]);
};
exports.default = usePageVisibility;
