"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPathFindingURL = void 0;
const getPathFindingURL = (start, destination, placeId) => {
    const agent = navigator.userAgent.toLowerCase();
    if (agent.indexOf("iphone") > -1 || agent.indexOf("android") > -1 || agent.indexOf("ipad") > -1) {
        return `https://map.kakao.com/link/to/${placeId}`;
    }
    return `https://map.kakao.com/?sName=${start}&eName=${destination}`;
};
exports.getPathFindingURL = getPathFindingURL;
