"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAccommodationReviewListQuery = exports.requestAccommodationReviewList = exports.useAccommodationCurationQuery = exports.requestAccommodationCuration = exports.useRegionTrafficQuery = exports.requestRegionTraffic = exports.useReviewAccommodationMutation = exports.useAccommodationDetailQuery = exports.useAccommodationReviewTagsQuery = exports.useAccommodationListByNameQuery = exports.requestAccommodationListByName = exports.useAccommodationListQuery = void 0;
const constants_1 = require("@/constants");
const services_1 = require("@/services");
const react_query_1 = require("react-query");
const requestAccommodationList = ({ queryKey, }) => __awaiter(void 0, void 0, void 0, function* () {
    const [, criteria] = queryKey;
    const items = yield (0, services_1.baseInstance)().get(constants_1.API_URL.GET_ACCOMMODATION_LIST, {
        params: criteria,
    });
    return items.data.accommodations;
});
const useAccommodationListQuery = (criteria, options) => {
    return (0, react_query_1.useQuery)([constants_1.QUERY_NAME.GET_ACCOMMODATION_LIST, criteria], requestAccommodationList, options);
};
exports.useAccommodationListQuery = useAccommodationListQuery;
const requestAccommodationListByName = ({ queryKey }) => __awaiter(void 0, void 0, void 0, function* () {
    const [, criteria] = queryKey;
    const items = yield (0, services_1.baseInstance)().get(constants_1.API_URL.GET_ACCOMMODATION_LIST_BY_NAME, {
        params: criteria,
    });
    return items.data;
});
exports.requestAccommodationListByName = requestAccommodationListByName;
const useAccommodationListByNameQuery = (criteria, options) => {
    return (0, react_query_1.useQuery)([constants_1.QUERY_NAME.GET_ACCOMMODATION_LIST_BY_NAME, criteria], exports.requestAccommodationListByName, options);
};
exports.useAccommodationListByNameQuery = useAccommodationListByNameQuery;
const requestAccommodationReviewTags = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, services_1.baseInstance)().get(constants_1.API_URL.GET_ACCOMMODATION_LIST);
});
const useAccommodationReviewTagsQuery = (options) => {
    return (0, react_query_1.useQuery)([constants_1.QUERY_NAME.GET_ACCOMMODATION_REVIEW_TAGS], requestAccommodationReviewTags, options);
};
exports.useAccommodationReviewTagsQuery = useAccommodationReviewTagsQuery;
const requestAccommodationDetail = ({ queryKey }) => __awaiter(void 0, void 0, void 0, function* () {
    const [, criteria] = queryKey;
    return yield (0, services_1.baseInstance)().get(constants_1.API_URL.GET_ACCOMMODATION_DETAIL(criteria.accommodationId));
});
const useAccommodationDetailQuery = (criteria, options) => {
    return (0, react_query_1.useQuery)([constants_1.QUERY_NAME.GET_ACCOMMODATION_DETAIL, criteria], requestAccommodationDetail, options);
};
exports.useAccommodationDetailQuery = useAccommodationDetailQuery;
const requestReviewAccommodation = (criteria) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, services_1.baseInstance)().post(constants_1.API_URL.GET_ACCOMMODATION_REVIEW(criteria.accommodationId), {
        tagNames: criteria.tags,
    });
});
const useReviewAccommodationMutation = () => {
    const queryClient = (0, react_query_1.useQueryClient)();
    return (0, react_query_1.useMutation)(requestReviewAccommodation, {
        onSuccess: (_, request) => {
            queryClient.invalidateQueries([constants_1.QUERY_NAME.GET_ACCOMMODATION_DETAIL, { id: request.accommodationId }]);
        },
    });
};
exports.useReviewAccommodationMutation = useReviewAccommodationMutation;
const requestRegionTraffic = ({ queryKey, }) => __awaiter(void 0, void 0, void 0, function* () {
    const [, { region }] = queryKey;
    return yield (0, services_1.baseInstance)().get(constants_1.API_URL.GET_REGION_TRAFFIC(region));
});
exports.requestRegionTraffic = requestRegionTraffic;
const useRegionTrafficQuery = (region) => {
    return (0, react_query_1.useQuery)([constants_1.QUERY_NAME.GET_REGION_TRAFFIC, region], exports.requestRegionTraffic);
};
exports.useRegionTrafficQuery = useRegionTrafficQuery;
const requestAccommodationCuration = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, services_1.baseInstance)().get(constants_1.API_URL.GET_ACCOMMODATION_CURATION);
});
exports.requestAccommodationCuration = requestAccommodationCuration;
const useAccommodationCurationQuery = () => {
    return (0, react_query_1.useQuery)([constants_1.QUERY_NAME.GET_ACCOMMODATION_CURATION], exports.requestAccommodationCuration);
};
exports.useAccommodationCurationQuery = useAccommodationCurationQuery;
const requestAccommodationReviewList = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, services_1.baseInstance)().get(constants_1.API_URL.GET_ACCOMMODATION_REVIEW_LIST);
});
exports.requestAccommodationReviewList = requestAccommodationReviewList;
const useAccommodationReviewListQuery = () => {
    return (0, react_query_1.useQuery)([constants_1.QUERY_NAME.GET_ACCOMMODATION_REVIEW_LIST], exports.requestAccommodationReviewList, { staleTime: Infinity, cacheTime: Infinity, keepPreviousData: true });
};
exports.useAccommodationReviewListQuery = useAccommodationReviewListQuery;
