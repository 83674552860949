"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useMap = ({ userCoordinates, pins }) => {
    const [centerCoordinates, setCenterCoordinates] = (0, react_1.useState)(userCoordinates);
    const [selectedPinId, setSelectedPinId] = (0, react_1.useState)();
    const selectPin = (0, react_1.useCallback)((id) => {
        const selectedPin = pins === null || pins === void 0 ? void 0 : pins.find((pin) => pin.id === id);
        setSelectedPinId(id);
        selectedPin && setCenterCoordinates({ lat: selectedPin.coordinates.lat, lng: selectedPin.coordinates.lng });
    }, [pins]);
    return {
        centerCoordinates,
        selectedPinId,
        selectPin,
    };
};
exports.default = useMap;
