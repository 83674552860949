"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.d2aMapper_GetWorkersResponse_WorkersInfo = exports.a2dMapper_WorkersCriteria_GetWorkersRequest = exports.d2aMapper_GetWorkerDetailResponse_WorkerDetailInfo = exports.a2dMapper_WorkerPinsCriteria_GetWorkerPinsRequest = exports.d2aMapper_GetWorkerPinsResponse_WorkerPinsInfo = void 0;
const map_1 = require("@/constants/map");
const d2aMapper_GetWorkerPinsResponse_WorkerPinsInfo = (response) => {
    return response.data.response.map((pin) => ({
        id: pin.id,
        coordinates: {
            lat: pin.latitude,
            lng: pin.longitude,
        },
    }));
};
exports.d2aMapper_GetWorkerPinsResponse_WorkerPinsInfo = d2aMapper_GetWorkerPinsResponse_WorkerPinsInfo;
const a2dMapper_WorkerPinsCriteria_GetWorkerPinsRequest = () => {
    return {
        kilometer: map_1.MAP.SearchRadius / 1000,
    };
};
exports.a2dMapper_WorkerPinsCriteria_GetWorkerPinsRequest = a2dMapper_WorkerPinsCriteria_GetWorkerPinsRequest;
const d2aMapper_GetWorkerDetailResponse_WorkerDetailInfo = (response) => {
    return {
        imageUrl: response.data.imageUrl,
        job: response.data.position.content,
        name: response.data.nickname,
        story: response.data.story,
        yearOfService: response.data.workingYear.content,
        desiredActivities: response.data.activities.map((activity) => ({
            icon: activity.name,
            text: activity.content,
        })),
        company: response.data.company,
        workChatCount: response.data.workchats,
    };
};
exports.d2aMapper_GetWorkerDetailResponse_WorkerDetailInfo = d2aMapper_GetWorkerDetailResponse_WorkerDetailInfo;
const a2dMapper_WorkersCriteria_GetWorkersRequest = () => {
    return {
        kilometer: map_1.MAP.SearchRadius / 1000,
    };
};
exports.a2dMapper_WorkersCriteria_GetWorkersRequest = a2dMapper_WorkersCriteria_GetWorkersRequest;
const d2aMapper_GetWorkersResponse_WorkersInfo = (response) => {
    return response.data.response.map((worker) => ({
        id: worker.id,
        imageUrl: worker.imageUrl,
        job: worker.position.content,
        name: worker.nickname,
        yearOfService: worker.workingYear.content,
        workchats: worker.workchats,
        tags: worker.activities.map((review) => review.content),
    }));
};
exports.d2aMapper_GetWorkersResponse_WorkersInfo = d2aMapper_GetWorkersResponse_WorkersInfo;
