"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useScrollDrag = () => {
    const ref = (0, react_1.useRef)(null);
    const [mouseX, setMouseX] = (0, react_1.useState)(0);
    const [scrollX, setScrollX] = (0, react_1.useState)(0);
    const [currentIndex, setCurrentIndex] = (0, react_1.useState)(0);
    (0, react_1.useEffect)(() => {
        var _a;
        (_a = ref.current) === null || _a === void 0 ? void 0 : _a.addEventListener("scroll", () => {
            setCurrentIndex(Math.round(ref.current.scrollLeft / ref.current.clientWidth));
        });
    }, []);
    const events = {
        onMouseDown: ({ nativeEvent: e }) => {
            setMouseX(e.clientX);
            setScrollX(ref.current.scrollLeft);
        },
        onMouseMove: ({ nativeEvent: e }) => {
            if (e.buttons > 0) {
                e.preventDefault();
                ref.current.scrollTo({
                    left: scrollX + mouseX - e.clientX,
                    behavior: "smooth",
                });
            }
        },
    };
    return { events, ref, currentIndex };
};
exports.default = useScrollDrag;
