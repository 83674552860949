"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("@/constants");
const stack_1 = require("@/stack");
const worker_1 = require("@/domains/worker");
const react_1 = require("react");
const useWorkerMap = ({ isReloaded, isSelected }) => {
    const { push } = (0, stack_1.useFlow)();
    const { data: workerPins, isLoading: isWorkerPinsLoading } = (0, worker_1.useWorkerPinsQuery)({
        enabled: isSelected && isReloaded,
        keepPreviousData: true,
        suspense: false,
    });
    const { data: workers, isLoading: isWorkersLoading } = (0, worker_1.useWorkersQuery)({
        enabled: isSelected && isReloaded,
        keepPreviousData: true,
        suspense: false,
    });
    const navigateToWorkerDetail = (0, react_1.useCallback)((id) => {
        push(constants_1.PATH.WORKER.stack, { workerId: id });
    }, [push]);
    return {
        workerPins,
        isWorkerPinsLoading,
        workers,
        isWorkersLoading,
        navigateToWorkerDetail,
    };
};
exports.default = useWorkerMap;
