"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INFO_TAG_NAMES = exports.REVIEW_TAG_NAMES = exports.ACCOMMODATION_REGIONS = void 0;
exports.ACCOMMODATION_REGIONS = ["ALL", "SEOUL", "JEJU", "GANGNEUNG", "SOKCHO"];
exports.REVIEW_TAG_NAMES = ["WIFI", "POWER", "SEAT", "QUIET", "FOCUS", "SERVE_MEAL"];
exports.INFO_TAG_NAMES = [
    "WORKSPACE",
    "NEAR_FOREST",
    "NEAR_SEA",
    "NEAR_ATTRACTION",
    "NEAR_CITY",
    "FREE_PARKING",
    "SHARED_WORKSPACE",
];
