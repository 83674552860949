"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.a2dMapper_UpdateUserInfoCommand_PutUserProfileRequest = exports.a2dMapper_SyncUserLocationCommand_PostSync = exports.d2aMapper_GetNearWorkersCountingResponse_NearWorkersCountInfo = exports.a2dMapper_UserAddressPinsCriteria_PostUserAddressRequest = void 0;
const a2dMapper_UserAddressPinsCriteria_PostUserAddressRequest = (criteria) => {
    return {
        latitude: criteria.lat,
        longitude: criteria.lng,
    };
};
exports.a2dMapper_UserAddressPinsCriteria_PostUserAddressRequest = a2dMapper_UserAddressPinsCriteria_PostUserAddressRequest;
const d2aMapper_GetNearWorkersCountingResponse_NearWorkersCountInfo = (response) => {
    return {
        count: response.count,
    };
};
exports.d2aMapper_GetNearWorkersCountingResponse_NearWorkersCountInfo = d2aMapper_GetNearWorkersCountingResponse_NearWorkersCountInfo;
const a2dMapper_SyncUserLocationCommand_PostSync = (command) => {
    return {
        latitude: command.lat,
        longitude: command.lng,
    };
};
exports.a2dMapper_SyncUserLocationCommand_PostSync = a2dMapper_SyncUserLocationCommand_PostSync;
const a2dMapper_UpdateUserInfoCommand_PutUserProfileRequest = (command) => {
    return {
        activities: command.desiredActivities,
        nickname: command.nickName,
        position: command.job,
        story: command.story,
        workingYear: command.yearOfService,
    };
};
exports.a2dMapper_UpdateUserInfoCommand_PutUserProfileRequest = a2dMapper_UpdateUserInfoCommand_PutUserProfileRequest;
