"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCafesQuery = exports.requestGetCafes = exports.usePostCafeReview = exports.requestPostCafeReview = exports.useCafeReviewListQuery = exports.requestCafeReviewList = exports.useCafeDetailQuery = exports.requestGetCafeDetail = exports.useCafePinsQuery = exports.requestGetCafePins = void 0;
const react_query_1 = require("react-query");
const Mapper = __importStar(require("./cafe.mapper"));
const constants_1 = require("@/constants");
const services_1 = require("@/services");
const requestGetCafePins = ({ queryKey }) => __awaiter(void 0, void 0, void 0, function* () {
    const [, criteria] = queryKey;
    const data = yield (0, services_1.baseInstance)().get("map/cafes/pin", {
        params: Mapper.a2dMapper_CafePinsCriteria_GetCafePinsRequest(criteria),
    });
    const cafePins = Mapper.d2aMapper_GetCafePinsResponse_CafePinsInfo(data);
    return cafePins;
});
exports.requestGetCafePins = requestGetCafePins;
const useCafePinsQuery = (criteria, options) => {
    return (0, react_query_1.useQuery)([constants_1.QUERY_NAME.GET_CAFE_PINS, criteria], exports.requestGetCafePins, options);
};
exports.useCafePinsQuery = useCafePinsQuery;
const requestGetCafeDetail = ({ queryKey }) => __awaiter(void 0, void 0, void 0, function* () {
    const [, criteria] = queryKey;
    const data = yield (0, services_1.baseInstance)().get(`map/cafes/${criteria.id}`);
    const cafeDetail = Mapper.d2aMapper_GetCafeDetailResponse_CafeDetailInfo(data);
    return cafeDetail;
});
exports.requestGetCafeDetail = requestGetCafeDetail;
const useCafeDetailQuery = (criteria, options) => {
    return (0, react_query_1.useQuery)([constants_1.QUERY_NAME.GET_CAFE_DETAIL, criteria], exports.requestGetCafeDetail, options);
};
exports.useCafeDetailQuery = useCafeDetailQuery;
const requestCafeReviewList = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, services_1.baseInstance)().get(constants_1.API_URL.GET_CAFE_REVIEW_LIST);
});
exports.requestCafeReviewList = requestCafeReviewList;
const useCafeReviewListQuery = () => {
    return (0, react_query_1.useQuery)([constants_1.QUERY_NAME.GET_CAFE_REVIEW_LIST], exports.requestCafeReviewList, { staleTime: Infinity, cacheTime: Infinity, keepPreviousData: true });
};
exports.useCafeReviewListQuery = useCafeReviewListQuery;
const requestPostCafeReview = ({ reviewTypeNames, locationId, }) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, services_1.baseInstance)().post(constants_1.API_URL.POST_CAFE_REVIEW(locationId), { reviewTypeNames });
});
exports.requestPostCafeReview = requestPostCafeReview;
const usePostCafeReview = () => {
    const queryClient = (0, react_query_1.useQueryClient)();
    return (0, react_query_1.useMutation)({
        mutationFn: exports.requestPostCafeReview,
        onSuccess: (_, request) => {
            queryClient.invalidateQueries([constants_1.QUERY_NAME.GET_CAFE_DETAIL, { id: request.locationId }]);
            queryClient.invalidateQueries([constants_1.QUERY_NAME.GET_CAFES]);
        },
    });
};
exports.usePostCafeReview = usePostCafeReview;
const requestGetCafes = ({ queryKey }) => __awaiter(void 0, void 0, void 0, function* () {
    const [, criteria] = queryKey;
    const data = yield (0, services_1.baseInstance)().get("/map/cafes", {
        params: Mapper.a2dMapper_CafesCriteria_GetCafesRequest(criteria),
    });
    const cafes = Mapper.d2aMapper_GetCafesResponse_CafesInfo(data);
    return cafes;
});
exports.requestGetCafes = requestGetCafes;
const useCafesQuery = (criteria, options) => {
    return (0, react_query_1.useQuery)([constants_1.QUERY_NAME.GET_CAFES, criteria], exports.requestGetCafes, options);
};
exports.useCafesQuery = useCafesQuery;
