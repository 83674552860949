"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const isHTMLElement = (target) => {
    return target instanceof HTMLElement;
};
const useIntersectionObserver = ({ root = null, target, onIntersect, threshold = 1.0, rootMargin = "0px", }) => {
    (0, react_1.useEffect)(() => {
        const observer = new IntersectionObserver(onIntersect, {
            root,
            rootMargin,
            threshold,
        });
        if (!target) {
            return;
        }
        if (isHTMLElement(target)) {
            observer === null || observer === void 0 ? void 0 : observer.observe(target);
        }
        return () => observer === null || observer === void 0 ? void 0 : observer.disconnect();
    }, [target, root, rootMargin, onIntersect, threshold]);
};
exports.default = useIntersectionObserver;
