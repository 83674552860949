"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Z_INDEX = void 0;
exports.Z_INDEX = {
    HIGHEST: 1200,
    HIGH: 900,
    MIDDLE: 600,
    LOW: 300,
    ROOT: 0,
    UNDER_ROOT: -1,
};
