"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QUERY_NAME = void 0;
exports.QUERY_NAME = {
    VALIDATE_NICKNAME: "VALIDATE_NICKNAME",
    GET_POSITION_LIST: "GET_POSITION_LIST",
    GET_WORKING_YEAR_LIST: "GET_WORKING_YEAR_LIST",
    GET_ACCOMMODATION_LIST: "GET_ACCOMMODATION_LIST",
    GET_ACCOMMODATION_LIST_BY_NAME: "GET_ACCOMMODATION_LIST_BY_NAME",
    GET_ACCOMMODATION_DETAIL: "GET_ACCOMMODATION_DETAIL",
    GET_ACCOMMODATION_REVIEW_TAGS: "GET_ACCOMMODATION_REVIEW_TAGS",
    GET_CAFE_PINS: "GET_CAFE_PINS",
    GET_CAFES: "GET_CAFES",
    GET_CAFE_DETAIL: "GET_CAFE_DETAIL",
    GET_CAFE_REVIEW_LIST: "GET_CAFE_REVIEW_LIST",
    GET_ACCOMMODATION_REVIEW_LIST: "GET_ACCOMMODATION_REVIEW_LIST",
    GET_DINERS: "GET_DINERS",
    GET_DINER_PINS: "GET_DINER_PINS",
    GET_DINER_DETAIL: "GET_DINER_DETAIL",
    GET_DINER_REVIEW_LIST: "GET_DINER_REVIEW_LIST",
    GET_WORKERS: "GET_WORKERS",
    GET_WORKER_PINS: "GET_WORKER_PINS",
    GET_WORKER_DETAIL: "GET_WORKER_DETAIL",
    GET_USER_ADDRESS: "GET_USER_LOCATION",
    GET_USER_INFO: "GET_USER_INFO",
    GET_EMAIL_VALIDATION_REMAIN_ATTEMPTS: "GET_EMAIL_VALIDATION_REMAIN_ATTEMPTS",
    GET_CHAT_LIST: "GET_CHAT_LIST",
    GET_CHAT: "GET_CHAT",
    GET_NEAR_WORKERS_COUNT: "GET_NEAR_WORKERS_COUNT",
    GET_REGION_TRAFFIC: "GET_REGION_TRAFFIC",
    GET_ACCOMMODATION_CURATION: "GET_ACCOMMODATION_CURATION",
};
