"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWorkersQuery = exports.requestGetWorkers = exports.useWorkerDetailQuery = exports.requestGetWorkerDetail = exports.useWorkerPinsQuery = exports.requestGetWorkerPins = void 0;
const react_query_1 = require("react-query");
const Mapper = __importStar(require("./worker.mapper"));
const constants_1 = require("@/constants");
const services_1 = require("@/services");
const requestGetWorkerPins = () => __awaiter(void 0, void 0, void 0, function* () {
    const data = yield (0, services_1.baseInstance)().get("map/workers/pins");
    const workerPins = Mapper.d2aMapper_GetWorkerPinsResponse_WorkerPinsInfo(data);
    return workerPins;
});
exports.requestGetWorkerPins = requestGetWorkerPins;
const useWorkerPinsQuery = (options) => {
    return (0, react_query_1.useQuery)([constants_1.QUERY_NAME.GET_WORKER_PINS], exports.requestGetWorkerPins, options);
};
exports.useWorkerPinsQuery = useWorkerPinsQuery;
const requestGetWorkerDetail = ({ queryKey, }) => __awaiter(void 0, void 0, void 0, function* () {
    const [, criteria] = queryKey;
    const data = yield (0, services_1.baseInstance)().get(`map/workers/${criteria.id}`);
    const workerDetail = Mapper.d2aMapper_GetWorkerDetailResponse_WorkerDetailInfo(data);
    return workerDetail;
});
exports.requestGetWorkerDetail = requestGetWorkerDetail;
const useWorkerDetailQuery = (criteria, options) => {
    return (0, react_query_1.useQuery)([constants_1.QUERY_NAME.GET_WORKER_DETAIL, criteria], exports.requestGetWorkerDetail, options);
};
exports.useWorkerDetailQuery = useWorkerDetailQuery;
const requestGetWorkers = () => __awaiter(void 0, void 0, void 0, function* () {
    const data = yield (0, services_1.baseInstance)().get("/map/workers");
    const workers = Mapper.d2aMapper_GetWorkersResponse_WorkersInfo(data);
    return workers;
});
exports.requestGetWorkers = requestGetWorkers;
const useWorkersQuery = (options) => {
    return (0, react_query_1.useQuery)([constants_1.QUERY_NAME.GET_WORKERS], exports.requestGetWorkers, options);
};
exports.useWorkersQuery = useWorkersQuery;
