"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBlockUser = exports.requestBlockUser = exports.useChatListQuery = exports.requestChatList = exports.useLastMessageQuery = exports.requestLastMessage = exports.requestChatMessages = exports.requestChat = exports.useChatConfirmQuery = exports.requestChatConfirm = exports.useChatRemoveQuery = exports.requestChatRemove = exports.useChatSendQuery = exports.requestChatSend = exports.useChatRoomCreateQuery = exports.requestChatRoomCreate = void 0;
const react_query_1 = require("react-query");
const constants_1 = require("@/constants");
const services_1 = require("@/services");
const requestChatRoomCreate = ({ ownerUserId, }) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, services_1.baseInstance)().post(constants_1.API_URL.POST_CHAT_ROOM_CREATE(ownerUserId));
});
exports.requestChatRoomCreate = requestChatRoomCreate;
const useChatRoomCreateQuery = () => {
    return (0, react_query_1.useMutation)({
        mutationFn: exports.requestChatRoomCreate,
    });
};
exports.useChatRoomCreateQuery = useChatRoomCreateQuery;
const requestChatSend = ({ roomId, message, }) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, services_1.baseInstance)().post(constants_1.API_URL.POST_CHAT_SEND(roomId, message));
});
exports.requestChatSend = requestChatSend;
const useChatSendQuery = () => {
    return (0, react_query_1.useMutation)({
        mutationFn: exports.requestChatSend,
    });
};
exports.useChatSendQuery = useChatSendQuery;
const requestChatRemove = ({ roomId, lastMessageId }) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, services_1.baseInstance)().delete(constants_1.API_URL.DELETE_CHAT(roomId, lastMessageId));
});
exports.requestChatRemove = requestChatRemove;
const useChatRemoveQuery = () => {
    return (0, react_query_1.useMutation)(exports.requestChatRemove);
};
exports.useChatRemoveQuery = useChatRemoveQuery;
const requestChatConfirm = ({ roomId, }) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, services_1.baseInstance)().post(constants_1.API_URL.POST_CHAT_CONFIRM(roomId));
});
exports.requestChatConfirm = requestChatConfirm;
const useChatConfirmQuery = () => {
    return (0, react_query_1.useMutation)({
        mutationFn: exports.requestChatConfirm,
    });
};
exports.useChatConfirmQuery = useChatConfirmQuery;
const requestChat = ({ messageId, roomId, sortType }) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, services_1.baseInstance)().get(constants_1.API_URL.GET_CHAT_MESSAGES(roomId, messageId, sortType));
});
exports.requestChat = requestChat;
const requestChatMessages = ({ queryKey, }) => __awaiter(void 0, void 0, void 0, function* () {
    const [, { messageId, roomId, sortType }] = queryKey;
    return yield (0, exports.requestChat)({ messageId, roomId, sortType });
});
exports.requestChatMessages = requestChatMessages;
const requestLastMessage = ({ roomId, lastMessageId, }) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, services_1.baseInstance)().post(constants_1.API_URL.POST_LAST_MESSAGE(roomId, lastMessageId));
});
exports.requestLastMessage = requestLastMessage;
const useLastMessageQuery = () => {
    return (0, react_query_1.useMutation)({
        mutationFn: exports.requestLastMessage,
    });
};
exports.useLastMessageQuery = useLastMessageQuery;
const requestChatList = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, services_1.baseInstance)().get(constants_1.API_URL.GET_CHAT_LIST);
});
exports.requestChatList = requestChatList;
const useChatListQuery = () => {
    return (0, react_query_1.useQuery)([constants_1.QUERY_NAME.GET_CHAT_LIST], exports.requestChatList);
};
exports.useChatListQuery = useChatListQuery;
const requestBlockUser = ({ blockUserId }) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, services_1.baseInstance)().post(constants_1.API_URL.POST_BLOCK_USER(blockUserId));
});
exports.requestBlockUser = requestBlockUser;
const useBlockUser = () => {
    return (0, react_query_1.useMutation)(exports.requestBlockUser);
};
exports.useBlockUser = useBlockUser;
