"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WORKING_YEAR = exports.POSITION = void 0;
exports.POSITION = {
    ENGINEER: "개발",
    DESIGNER: "디자이너",
    PRODUCT: "기획 / PM",
    MARKETING: "마케팅",
    OFFICE: "사무직",
    EXPERT: "전문직",
    FREE: "프리랜서",
};
exports.WORKING_YEAR = {
    NEW_STAFF: "1~2년차",
    JUNIOR: "3~4년차",
    MID_LEVEL: "5~6년차",
    SENIOR: "7~8년차",
    EXPERT: "9~10년차",
    OVER: "11년차~",
};
