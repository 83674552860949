"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.theme = void 0;
const colors = {
    mainColor: "#0000FF",
    green: "#22DF9F",
    error: "#F43327",
    yellow: "#FFC42C",
    white: "#fff",
    black: "#121212",
    gray01: "#333",
    gray02: "#565656",
    gray03: "#636363",
    gray04: "#747474",
    gray05: "#949494",
    gray06: "#bbb",
    gray07: "#d6d6d6",
    gray08: "#ededed",
    gray09: "#f5f5f5",
    gray10: "#f9f9f9",
    gray11: "#8b95a1",
    jeju: "#FF7A00",
    gangWon: "#492CFF",
    seoul: "#FF5151",
};
const fonts = {
    Bold00: {
        fontSize: "32px",
        lineHeight: "140%",
        fontWeight: 700,
    },
    Bold01: {
        fontSize: "28px",
        lineHeight: "140%",
        fontWeight: 700,
    },
    Bold02: {
        fontSize: "24px",
        lineHeight: "140%",
        fontWeight: 700,
    },
    Bold03: {
        fontSize: "20px",
        lineHeight: "140%",
        fontWeight: 700,
    },
    Bold04: {
        fontSize: "17px",
        lineHeight: "140%",
        fontWeight: 700,
    },
    Bold05: {
        fontSize: "14px",
        lineHeight: "140%",
        fontWeight: 700,
    },
    Bold06: {
        fontSize: "12px",
        lineHeight: "140%",
        fontWeight: 700,
    },
    Bold07: {
        fontSize: "11px",
        lineHeight: "125%",
        fontWeight: 700,
    },
    Bold08: {
        fontSize: "14px",
        lineHeight: "125%",
        fontWeight: 700,
    },
    SemiBold00: {
        fontSize: "22px",
        lineHeight: "135%",
        fontWeight: 600,
    },
    SemiBold01: {
        fontSize: "20px",
        lineHeight: "135%",
        fontWeight: 600,
    },
    SemiBold02: {
        fontSize: "18px",
        lineHeight: "135%",
        fontWeight: 600,
    },
    SemiBold03: {
        fontSize: "16px",
        lineHeight: "135%",
        fontWeight: 600,
    },
    SemiBold04: {
        fontSize: "11px",
        lineHeight: "135%",
        fontWeight: 600,
    },
    Medium00: {
        fontSize: "22px",
        lineHeight: "125%",
        fontWeight: 500,
    },
    Medium01: {
        fontSize: "18px",
        lineHeight: "140%",
        fontWeight: 500,
    },
    Medium02: {
        fontSize: "16px",
        lineHeight: "140%",
        fontWeight: 500,
    },
    Medium03: {
        fontSize: "14px",
        lineHeight: "140%",
        fontWeight: 500,
    },
    Medium04: {
        fontSize: "12px",
        lineHeight: "160%",
        fontWeight: 500,
    },
    Medium05: {
        fontSize: "12px",
        lineHeight: "125%",
        fontWeight: 500,
    },
    Medium06: {
        fontSize: "11px",
        lineHeight: "140%",
        fontWeight: 500,
    },
    Regular01: {
        fontSize: "20px",
        lineHeight: "140%",
        fontWeight: 400,
    },
    Regular02: {
        fontSize: "18px",
        lineHeight: "140%",
        fontWeight: 400,
    },
    Regular03: {
        fontSize: "16px",
        lineHeight: "145%",
        fontWeight: 400,
    },
    Regular04: {
        fontSize: "15px",
        lineHeight: "135%",
        fontWeight: 400,
    },
};
exports.theme = {
    colors,
    fonts,
};
