"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decimalFormatter = void 0;
function decimalFormatter(value, currencyStyle) {
    if (!value || !Number(value))
        return "0";
    const amount = new Intl.NumberFormat("ko-KR", Object.assign({}, currencyStyle)).format(value);
    return `${amount}`;
}
exports.decimalFormatter = decimalFormatter;
