"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useReLoadButton = () => {
    const [lastReloadTimeStamp, setLastReloadTimeStamp] = (0, react_1.useState)(new Date().getTime());
    const lastReloadTimeStampRef = (0, react_1.useRef)(lastReloadTimeStamp - 1);
    const updateReloadTime = (0, react_1.useCallback)(() => {
        const currentTimestamp = new Date().getTime();
        setLastReloadTimeStamp(currentTimestamp);
    }, []);
    (0, react_1.useEffect)(() => {
        lastReloadTimeStampRef.current = lastReloadTimeStamp;
    }, [lastReloadTimeStamp]);
    return {
        isReloaded: lastReloadTimeStampRef.current !== lastReloadTimeStamp,
        updateReloadTime,
    };
};
exports.default = useReLoadButton;
