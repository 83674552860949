"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccommodationRegions_TEXT = void 0;
exports.AccommodationRegions_TEXT = {
    GANGNEUNG: "강릉",
    JEJU: "제주",
    SEOUL: "서울",
    SOKCHO: "속초",
    ALL: "전체",
};
