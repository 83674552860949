"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAddUserReportMutation = exports.requestPostUserReport = exports.useUploadUserProfileImageMutation = exports.requestUploadUserProfileImage = exports.useUserLocationTrackingToggleMutation = exports.requestUserLocationTrackingToggle = exports.useUpdateUserProfileMutation = exports.requestUpdateUserProfile = exports.useUserInfo = exports.requestGetUserInfo = exports.requestGetUserInfoBase = exports.useSyncUserLocationMutation = exports.requestPostSync = exports.useNearWorkersCountQuery = exports.requestGetNearWorkersCounting = exports.useUserAddressQuery = exports.requestGetUserAddress = void 0;
const react_query_1 = require("react-query");
const Mapper = __importStar(require("./user.mapper"));
const constants_1 = require("@/constants");
const services_1 = require("@/services");
const requestGetUserAddress = ({ queryKey, }) => __awaiter(void 0, void 0, void 0, function* () {
    const [, criteria] = queryKey;
    const data = yield (0, services_1.baseInstance)().post("/users/address", Mapper.a2dMapper_UserAddressPinsCriteria_PostUserAddressRequest(criteria));
    return data.data.address;
});
exports.requestGetUserAddress = requestGetUserAddress;
const useUserAddressQuery = (criteria, options) => {
    return (0, react_query_1.useQuery)([constants_1.QUERY_NAME.GET_USER_ADDRESS, criteria], exports.requestGetUserAddress, options);
};
exports.useUserAddressQuery = useUserAddressQuery;
const requestGetNearWorkersCounting = () => __awaiter(void 0, void 0, void 0, function* () {
    const data = yield (0, services_1.baseInstance)().get("/map/workers/counting");
    return data.data;
});
exports.requestGetNearWorkersCounting = requestGetNearWorkersCounting;
const useNearWorkersCountQuery = (options) => {
    return (0, react_query_1.useQuery)([constants_1.QUERY_NAME.GET_NEAR_WORKERS_COUNT], exports.requestGetNearWorkersCounting, options);
};
exports.useNearWorkersCountQuery = useNearWorkersCountQuery;
const requestPostSync = (command) => __awaiter(void 0, void 0, void 0, function* () {
    yield (0, services_1.baseInstance)().post("/", {
        params: Mapper.a2dMapper_SyncUserLocationCommand_PostSync(command),
    });
});
exports.requestPostSync = requestPostSync;
const useSyncUserLocationMutation = () => {
    return (0, react_query_1.useMutation)(exports.requestPostSync);
};
exports.useSyncUserLocationMutation = useSyncUserLocationMutation;
const requestGetUserInfoBase = () => (0, services_1.baseInstance)().get(constants_1.API_URL.GET_USER_LIST);
exports.requestGetUserInfoBase = requestGetUserInfoBase;
const requestGetUserInfo = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield (0, services_1.baseInstance)().get(constants_1.API_URL.GET_USER_LIST);
    return response.data;
});
exports.requestGetUserInfo = requestGetUserInfo;
const useUserInfo = (options) => {
    return (0, react_query_1.useQuery)([constants_1.QUERY_NAME.GET_USER_INFO], exports.requestGetUserInfo, options);
};
exports.useUserInfo = useUserInfo;
const requestUpdateUserProfile = (command) => __awaiter(void 0, void 0, void 0, function* () {
    return (0, services_1.baseInstance)().put(constants_1.API_URL.UPDATE_USER_PROFILE, Mapper.a2dMapper_UpdateUserInfoCommand_PutUserProfileRequest(command));
});
exports.requestUpdateUserProfile = requestUpdateUserProfile;
const useUpdateUserProfileMutation = () => {
    const queryClient = (0, react_query_1.useQueryClient)();
    queryClient.invalidateQueries(constants_1.QUERY_NAME.GET_USER_INFO);
    return (0, react_query_1.useMutation)(exports.requestUpdateUserProfile);
};
exports.useUpdateUserProfileMutation = useUpdateUserProfileMutation;
const requestUserLocationTrackingToggle = (turnOff) => __awaiter(void 0, void 0, void 0, function* () {
    yield (0, services_1.baseInstance)().put(constants_1.API_URL.PUT_USER_LOCATION_TRACKING, undefined, {
        params: {
            turnOff: turnOff,
        },
    });
});
exports.requestUserLocationTrackingToggle = requestUserLocationTrackingToggle;
const useUserLocationTrackingToggleMutation = () => (0, react_query_1.useMutation)(exports.requestUserLocationTrackingToggle);
exports.useUserLocationTrackingToggleMutation = useUserLocationTrackingToggleMutation;
const requestUploadUserProfileImage = (file) => __awaiter(void 0, void 0, void 0, function* () {
    const formData = new FormData();
    formData.append("file", file);
    yield (0, services_1.baseInstance)().post(constants_1.API_URL.UPDATE_USER_PROFILE_IMAGE, formData);
});
exports.requestUploadUserProfileImage = requestUploadUserProfileImage;
const useUploadUserProfileImageMutation = () => (0, react_query_1.useMutation)(exports.requestUploadUserProfileImage);
exports.useUploadUserProfileImageMutation = useUploadUserProfileImageMutation;
const requestPostUserReport = (report) => (0, services_1.baseInstance)().post(constants_1.API_URL.POST_USER_REPORT, report);
exports.requestPostUserReport = requestPostUserReport;
const useAddUserReportMutation = () => (0, react_query_1.useMutation)(exports.requestPostUserReport);
exports.useAddUserReportMutation = useAddUserReportMutation;
